import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { TypedIcon } from 'typed-design-system'

import {
  Body1,
  Caption,
  Heading1,
  Heading3,
} from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'
import { LandingPagePrimaryButton } from '@/src/styles/landingPageCommonStyles'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import { MediaQuery } from '@/src/utils/enums'

interface HeroImageLayoutProps {
  heading3Text?: string
  heading1Text: string
  body1Texts: string[]
  exampleImages: React.ReactNode[]
}

export default function HeroImageLayout({
  heading3Text,
  heading1Text,
  body1Texts,
  exampleImages,
}: HeroImageLayoutProps) {
  const {
    textWithLocale: {
      website: { heroImageFixedTxts },
    },
  } = useContext(UserLanguageContext)
  return (
    <HeroImageLayoutWrapper>
      <HeroImageHeader>
        {heading3Text && (
          <Heading3 style={{ color: colors.gray11() }}>{heading3Text}</Heading3>
        )}
        <Heading1 style={{ color: colors.gray11() }}>{heading1Text}</Heading1>
        <BodyTextBox>
          {body1Texts.map((text) => {
            return (
              <Body1 style={{ color: colors.gray40() }} key={text}>
                {text}
              </Body1>
            )
          })}
        </BodyTextBox>
      </HeroImageHeader>
      <GetStartBox>
        <LinkBox>
          <ProductHuntButton
            href='https://www.producthunt.com/products/typed'
            target='_blank'
          >
            <img
              src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=360066&theme=light&period=daily'
              alt='Typed - Next&#0045;generation&#0032;document&#0032;editor&#0032;and&#0032;collaboration&#0032;tool | Product Hunt'
            />
          </ProductHuntButton>
        </LinkBox>
        <GetStartTextBox></GetStartTextBox>
      </GetStartBox>
      <ExampleImageBox>{exampleImages}</ExampleImageBox>
    </HeroImageLayoutWrapper>
  )
}

const HeroImageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const BodyTextBox = styled.div`
  text-align: center;
`

const HeroImageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  text-align: center;
`

const GetStartBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  margin-top: 40px;
`

const GetStartButton = styled(LandingPagePrimaryButton)`
  ${MediaQuery.NON_MOBILE} {
    gap: 8px;
    width: 280px;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;

    svg {
      width: 28px;
    }
  }
  ${MediaQuery.MOBILE} {
    gap: 4px;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;

    svg {
      width: 19px;
    }
  }
`

const ProductHuntButton = styled.a`
  ${MediaQuery.NON_MOBILE} {
    width: 280px;
    height: 60px;
  }
  ${MediaQuery.MOBILE} {
    width: 150px;
    height: 40px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`

const GetStartTextBox = styled.div`
  display: flex;

  ${MediaQuery.NON_MOBILE} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }
  ${MediaQuery.MOBILE} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const GetStartText = styled(Caption)`
  font-size: 12px;
  color: ${colors.gray50()};
`

const ExampleImageBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${MediaQuery.PC} {
    margin-top: 60px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 40px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 30px;
  }
`

const LinkBox = styled.div`
  display: flex;
  align-items: center;

  ${MediaQuery.PC} {
    gap: 14px;
  }
  ${MediaQuery.TABLET} {
    gap: 14px;
  }
  ${MediaQuery.MOBILE} {
    gap: 7px;
    flex-direction: column;
  }
`
