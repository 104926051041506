import React, { useContext } from 'react'
import styled from '@emotion/styled'

import { MediaQuery } from '@/src/utils/enums'
import colors from '@/src/styles/colors'
import {
  Body1,
  LandingPageFlatButton,
  Heading1,
  LandingPagePrimaryButton,
} from '@/src/styles/landingPageCommonStyles'
import { UserLanguageContext } from '@/src/locale/LocaleContext'

export default function StartTyped() {
  const {
    isKorean,
    textWithLocale: {
      website: { startTyped },
    },
  } = useContext(UserLanguageContext)
  return (
    <StartTypedWrapper>
      <StartTypedTextBox>
        <Heading1 style={{ color: colors.gray11() }}>
          {startTyped.headingTxt}
        </Heading1>
        {isKorean && (
          <Body1 style={{ color: colors.gray33() }}>{startTyped.bodyTxt}</Body1>
        )}
      </StartTypedTextBox>
    </StartTypedWrapper>
  )
}

const StartTypedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${MediaQuery.PC} {
    gap: 48px;
  }
  ${MediaQuery.TABLET} {
    gap: 32px;
  }
  ${MediaQuery.MOBILE} {
    gap: 24px;
  }
`

const StartTypedTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${MediaQuery.PC} {
    gap: 24px;
  }
  ${MediaQuery.TABLET} {
    gap: 16px;
  }
  ${MediaQuery.MOBILE} {
    gap: 12px;
  }
`

const StartTypedButtonBox = styled.div`
  display: flex;

  ${MediaQuery.PC} {
    gap: 20px;
  }
  ${MediaQuery.TABLET} {
    gap: 16px;
  }
  ${MediaQuery.MOBILE} {
    gap: 16px;
  }
`

const GetStartedButton = styled(LandingPagePrimaryButton)`
  ${MediaQuery.NON_MOBILE} {
    gap: 8px;
    width: 280px;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;

    svg {
      width: 28px;
    }
  }
  ${MediaQuery.MOBILE} {
    gap: 4px;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;

    svg {
      width: 19px;
    }
  }
`

const IntroductionButton = styled(LandingPageFlatButton)`
  white-space: nowrap;
  ${MediaQuery.NON_MOBILE} {
    gap: 8px;
    min-width: 280px;
    padding: 0 24px;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;

    svg {
      width: 28px;
    }
  }
  ${MediaQuery.MOBILE} {
    gap: 4px;
    min-width: 150px;
    padding: 0 12px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;

    svg {
      width: 19px;
    }
  }
`
